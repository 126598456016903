.cultural-modal .slider-btn{
    position: absolute;
    top: 3%;
    right: 2%;
    z-index: 1200;
    /* width: 2rem !important; */
    /* height: 2rem !important; */
    /* box-shadow: 0 1.2px 8px rgba(0,0,0,.4); */
    /* background: rgba(255, 255, 255, 0.8) !important; */
    /* padding: 0 0 0.2rem 0 !important; */
    /* --bs-btn-close-color: #000000 !important; */
    opacity: 0.7 !important;
}