.UserManagment {
  background-color: #eff2f5;
}

.UserManagment .custom-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.UserManagment .headingtext-color {
  /* color: #64748B; */
  /* color: rgb(71, 147, 213); */
  color: #64748b;
}

.UserManagment.winner-Section {
  background-color: #eff2f5;
}

.UserManagment .winner_background {
  background-image: url("../assets/img/emoji.png");
  background-repeat: repeat;
  background: cover;
  background-size: 105% 105%;
}
#hover-button {
  color: black;
  border: none;
}
#hover-button:hover {
  background-color: #3771c8;
  color: white;
}

.UserManagment .dates {
  color: #939eaf;
}

.UserManagment .applicants {
  color: #6d7580;
}

.UserManagment td {
  vertical-align: middle;
}

.UserManagment .rotateArrow {
  /* transform: scaleX(8); */
}

/* 
.UserManagment .search-input {
    position: relative;
    top:40%;
    left:50%
}

.UserManagment .search-icon {
    position: absolute;
    top: 60%;
    left: 10px;
    transform: translateY(-50%);
    
} */

/* ----------------------- */
/* Add this to your UserManagment.css or your global styles */

.UserManagment .search-container {
  position: relative;
}

.UserManagment input[type="text"] {
  padding: 10px;

  border-radius: 4px;
  margin-right: -1px; /* Adjust this value based on your design */
}

.UserManagment button.search-button {
  position: absolute;
  right: 0;
  top: 1%;
  right: 6%;
  padding: 10px;
  height: 30%;
  /* background-color: #007bff; */
  background-color: white;
  color: #0c0c0c; /* Change the text color as needed */
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.UserManagment .forTextSize {
  font-size: 90%;
}

.UserManagment .buttonFont {
  font-size: 79%;
}
.UserManagment .edit {
  background-color: #dfeaf1;
  color: #01477a;
}
/* Optional: Add a hover effect */
/* button.search-button:hover {
    background-color: #0056b3;
  }
   */

.UserManagment .edit {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.UserManagment .addButton {
  /* color: #01477A;
  background-color: #dae8fa; */
  color: white;
  /* background-color: #dae8fa; */
  background-color: #64748b;
}

/* .UserManagment .custom-button:disabled {
    cursor: not-allowed;
  } */

.UserManagment .colorForBackground {
  background-color: #f9fafc;
}

/* toggle */

/* toggle in label designing */
.toggle {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 52px;
  background-color: red;
  border-radius: 30px;
  border: 2px solid gray;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gray;
  top: 1px;
  left: 1px;
  transition: all 0.5s;
}

/* Toggle text */
/* p {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
        */
/* Checkbox checked effect */
.UserManagment .checkbox:checked + .toggle::after {
  left: 49px;
}

/* Checkbox checked toggle label bg color */
.UserManagment .checkbox:checked + .toggle {
  background-color: green;
}

/* Checkbox vanished */
.UserManagment .checkbox {
  display: none;
}

/* loader */
.UserManagment .loader-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserManagment .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.UserManagment .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* 
@media (min-width:1900px) and (max-width:2000px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      max-width: 1533px;
  }
} */
