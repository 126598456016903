.loader-container {
    position: relative; /* Set parent container to relative position */
    width: 100%; /* Adjust width as needed */
    height: 85vh; /* Adjust height as needed */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column; /* Stack elements vertically */
  }
  
  .loader-wrapper {
    position: relative; /* Set wrapper to relative position */
    margin-top: -82px; /* Move loader up by 50px */
  }
  
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(
      -50%,
      -50%
    ); /* Translate back by half of the loader's size */
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid #0b599e;
    border-left: 4px solid transparent;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (min-width: 1601px) and (max-width: 1920px) {
    .loader {
      width: 18%;
      height: 18%;
    }
  
    .loader::after {
      width: 18%;
      height: 18%;
      min-width: 65px; /* Sets a minimum width to ensure it doesn't get too small */
      min-height: 65px; /* Sets a minimum height to ensure it doesn't get too small */
    }
  }
  
  @media screen and (min-width: 1921px) {
    .loader-container .loader {
      width: 80px;
      height: 80px;
    }
  
    .loader-container .loader::after {
      width: 20%;
      height: 20%;
    }
  }
  
  @media screen and (min-width: 1600px) {
    .loader-container h1 {
      font-size: 34px !important;
    }
    .loader-container h2 {
      font-size: 30px !important;
    }
    .loader-container h3 {
      font-size: 26px !important;
    }
    .loader-container h4 {
      font-size: 22px !important;
    }
    .loader-container h5 {
      font-size: 20px !important;
    }
    .loader-container h6 {
      font-size: 18px !important;
    }
    .loader-container p {
      font-size: 16px !important;
    }
  }
  
  @media screen and (min-width: 1920px) {
    .loader-container h1 {
      font-size: 36px !important;
    }
    .loader-container h2 {
      font-size: 32px !important;
    }
    .loader-container h3 {
      font-size: 28px !important;
    }
    .loader-container h4 {
      font-size: 24px !important;
    }
    .loader-container h5 {
      font-size: 22px !important;
    }
    .loader-container h6 {
      font-size: 20px !important;
    }
    .loader-container p {
      font-size: 18px !important;
    }
  }
  @media screen and (min-width: 4480px) {
    .loader-container h1 {
      font-size: 48px !important;
    }
    .loader-container h2 {
      font-size: 44px !important;
    }
    .loader-container h3 {
      font-size: 40px !important;
    }
    .loader-container h4 {
      font-size: 36px !important;
    }
    .loader-container h5 {
      font-size: 32px !important;
    }
    .loader-container h6 {
      font-size: 30px !important;
    }
    .loader-container p {
      font-size: 28px !important;
    }
  }
  
  @media screen and (min-width: 1280px) {
    .loader-container h1 {
      font-size: 32px !important;
    }
    .loader-container h2 {
      font-size: 28px !important;
    }
    .loader-container h3 {
      font-size: 24px !important;
    }
    .loader-container h4 {
      font-size: 20px !important;
    }
    .loader-container h5 {
      font-size: 18px !important;
    }
    .loader-container h6 {
      font-size: 16px !important;
    }
    .loader-container p {
      font-size: 14px !important;
    }
  }
  
  @media screen and (min-width: 1400px) {
    .loader-container h1 {
      font-size: 34px !important;
    }
    .loader-container h2 {
      font-size: 30px !important;
    }
    .loader-container h3 {
      font-size: 26px !important;
    }
    .loader-container h4 {
      font-size: 22px !important;
    }
    .loader-container h5 {
      font-size: 20px !important;
    }
    .loader-container h6 {
      font-size: 18px !important;
    }
    .loader-container p {
      font-size: 16px !important;
    }
  }
  
  @media screen and (min-width: 1680px) {
    .loader-container h1 {
      font-size: 36px !important;
    }
    .loader-container h2 {
      font-size: 32px !important;
    }
    .loader-container h3 {
      font-size: 28px !important;
    }
    .loader-container h4 {
      font-size: 24px !important;
    }
    .loader-container h5 {
      font-size: 22px !important;
    }
    .loader-container h6 {
      font-size: 20px !important;
    }
    .loader-container p {
      font-size: 18px !important;
    }
  }
  