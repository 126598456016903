
/* Style to remove clear button */
input[type="date"]::-webkit-clear-button {
  display: none !important;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
}
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
}

input::-ms-clear {
  width: 0;
  height: 0;
}

/***** Common *****/
.height-width-svg {
  height: 1.5rem;
  width: 1.5rem;
}
/***** End Common *****/
.google-meet .btn:disabled{
  border: none !important;
}
/***** Blur Window *****/
.blur-section .blur-content {
  /* backdrop-filter: blur(50px); */
  filter: blur(5px);
}
/***** End Blur Window *****/

/***** Google Meet *****/
.google-meet input:focus-visible {
  outline: none;
}
.google-meet label{
  color: grey;
}
/* .google-meet .meet-green {
  color: #005128;
}
.google-meet .btn-outline-success {
  --bs-btn-color: #005128;
  --bs-btn-border-color: #005128;
  --bs-btn-hover-bg: #005128;
} */
/***** End Google Meet *****/
.google-meet select {
  background-position-x: 100% !important; /* arrow position for dropdown */
  background-position-y: 50%;
  --bs-offcanvas-width: 25%;
}

.loader-container-contact {
  position: relative; /* Step 1: Set parent container to relative position */
  width: 100%; /* Optional: Adjust width as needed */
  height: 50%; /* Optional: Adjust height as needed */
  display: flex; /* Optional: Use flexbox for centering */
  justify-content: center; /* Optional: Center horizontally */
  align-items: center; /* Optional: Center vertically */
}

.loader-contact {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-contact::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%; /* Step 2: Center horizontally */
  top: 50%; /* Step 3: Center vertically */
  transform: translate(-50%, -50%); /* Step 4: Translate back by half of the loader's size */
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

    


.calendar .card-body {
  padding: .5rem;
}