.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 5 text input with search icon */
.has-search .form-control {
  padding-left: 2.375rem;
}
.table .headingloan-color {
  color: #64748b;
}

.input-hide input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.admin-table .custom-container {
  background-color: #f9fafc;
}

@media (min-width: 1920px) and (max-width: 1950px) {
  .admin-table .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1533px;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .admin-table .container {
    max-width: 1134px;
  }
}
