/* Whatsapp connect */
#whatsapp .wtsapp {
  position: fixed;
  transform: all 0.5s ease;
  -webkit-transform: all 0.5s ease;
  -moz-transform: all 0.5s ease;
  -ms-transform: all 0.5s ease;
  -o-transform: all 0.5s ease;
  /* background-color: #25d366; */
  display: block;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-right: none;
  bottom: 30px;
  right: 25px;
  font-weight: 700;
  font-size: 20px;
  border: 0;
  z-index: 9999;
  width: 50px;
  height: 50px;
  /* line-height: 48px; */
}
/* #whatsapp .wtsapp:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background-color: #485b4fd6;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

#whatsapp .wtsapp:focus {
  border: none;
  outline: none;
} */

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/* Bounce whatsapp logo */
.bounce2 {
  animation: bounce2 2s ease infinite;
}

/* get in touch .whatsapp-connect */
.whats {
  box-shadow: 3px 6.2px 8px rgba(0, 0, 0, 0.2);
}
