.EditSchlorship{
    background-color: #EFF2F5;
}
.EditSchlorship .schlorshipBackground{
background-color: #F9FAFC;
}


.EditSchlorship .toAvoidOverflow
{
    display: "inline";
    overflow-wrap: 'break-word'
}

.EditSchlorship .forInputFiled{
    font-weight: 400;
    border: 'revert-layer';
    outline: "none";   
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
  }
  

  .EditSchlorship .forInputFiled{
    border: 'revert-layer';
    outline: "none";
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }