/* Default styles for all breakpoints */
.custom-style {
  /* Your default styles here */
}

/* Media query for extra-small devices (e.g., smartphones) */
@media (max-width: 300px) and (max-width: 521px) {
  .custom-style {
    /* Your styles for small devices here */
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .gbtn {
    /* padding-inline: ; */
  }
  .modal-header-signin .btn-close-signin {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(0.5 * var(--bs-modal-header-padding-x)) calc(2.5 * var(--bs-modal-header-padding-y)) auto !important;
  }
}

/* Media query for small devices (e.g., smartphones) */
@media (min-width: 522px) and (max-width: 576px) {
  .custom-style {
    /* Your styles for small devices here */
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .gbtn {
    padding-inline: 3em;
  }
  .modal-header-signin .btn-close-signin {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(0.5 * var(--bs-modal-header-padding-x)) calc(4.5 * var(--bs-modal-header-padding-y)) auto !important;
    /* margin-top: ;
    margin-right: ;
    margin-bottom: ;
    margin-left: ; */
  }
}

/* Media query for medium devices (e.g., tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .custom-style {
    /* Your styles for medium devices here */
    margin-left: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .gbtn {
    padding-inline: 3em;
  }
  .modal-header-signin .btn-close-signin {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(0.5 * var(--bs-modal-header-padding-x)) calc(4.5 * var(--bs-modal-header-padding-y)) auto !important;
    /* margin-top: ;
    margin-right: ;
    margin-bottom: ;
    margin-left: ; */
  }
}

/* Media query for large devices (e.g., laptops) */
@media (min-width: 769px) and (max-width: 992px) {
  .custom-style {
    /* Your styles for large devices here */
    margin-left: 15rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .gbtn {
    padding-inline: 3em;
  }
  .modal-header-signin .btn-close-signin {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(0.5 * var(--bs-modal-header-padding-x)) calc(4.5 * var(--bs-modal-header-padding-y)) auto !important;
    /* margin-top: ;
    margin-right: ;
    margin-bottom: ;
    margin-left: ; */
  }
}

/* Media query for extra-large devices (e.g., desktops) */
@media (min-width: 993px) {
  .custom-style {
    /* Your styles for extra-large devices here */
    margin-left: 5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .gbtn {
    padding-inline: 3em;
  }
  .modal-header-signin .btn-close-signin {
  padding: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(4.5 * var(--bs-modal-header-padding-y)) auto !important;
  /* margin-top: ;
  margin-right: ;
  margin-bottom: ;
  margin-left: ; */
}
}

/* .modal-header-signin .btn-close-signin {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(0.5 * var(--bs-modal-header-padding-x)) calc(4.5 * var(--bs-modal-header-padding-y)) auto !important;
  /* margin-top: ;
  margin-right: ;
  margin-bottom: ;
  margin-left: ; 
} */

.close-button {
  position: absolute;
  top: 10px; /* Adjust the top spacing as needed */
  right: 10px; /* Adjust the right spacing as needed */
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.2); /* Light border */
  border-top: 3px solid #ffff; /* Blue spinner */
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}