/* import 'bootstrap/dist/css/bootstrap.min.css'; */


@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');



body{
  font-family: 'Josefin Sans', sans-serif !important;
}

.modal-header {
 border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* .custom-offcanvas {
  --offcanvas-width: 50%;
} */


.student-side .text-primary{
  color: #2167b1 !important  ;
}

.btn-primary{
  background-color: #2167b1 !important  ;
}

.text-justify{
  text-align: justify !important;
}


/* width */
::-webkit-scrollbar {
  width: 6px; /* Width of the entire scrollbar */
  height: 6px;
  padding-left: 5px; /* Height of the entire scrollbar */
  padding-right: 5px; /* Height of the entire scrollbar */
  margin-left: 5px;
  margin-right: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 20px; /* Rounded corners */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}