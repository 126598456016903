.p2shighlight-card {
  border: none !important;
}

.p2s-card-height {
  /* max-height:"830px" */
}

@media (min-width: 2000px) and (max-width: 4000px) {
}

@media (min-width: 1400px) and (max-width: 2000px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}

@media (min-width: 992px) and (max-width: 1200px) {
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (min-width: 480px) and (max-width: 768px) {
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 12px; /* Modify the font size as needed */
    margin: 3px;
    padding: 3px;
  }
  .smallbutton-font {
    font-size: x-small;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .card-img-overlay {
    /* height: fit-content !important; */
    /* font-size:small; */
  }
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 11px !important; /* Modify the font size as needed */
  }
  .smallbutton-font {
    font-size: small;
  }
}

@media screen and (max-width: 320px) {
  .card-img-overlay {
    /* height: fit-content !important; */
    /* font-size: x-small; */
  }
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 12px; /* Modify the font size as needed */
  }
  .smallbutton-font {
    font-size: x-small;
  }
}

/* @media(min-width: 390px)and(max-width: 405px) {
  .forMarginInMobile {
    margin-top: 5.5rem;
  }
} */
