.ScholarshipManagment {
  background-color: #eff2f5;
}

.ScholarshipManagment .for-background {
  background-color: #f9fafc;
}

.ScholarshipManagment .headingtext-color {
  color: #64748b;
}

.ScholarshipManagment .custom-table {
  border-radius: 10px; /* Adjust the radius as needed */
  overflow: hidden;
}

.ScholarshipManagment .peginationButton {
  /* background-color: #F9FAFC;; */
  border: "none";
  background-color: "transparent";
  /* background-color: 'transparent' */
  color: "inherit";
  padding: "0";
  cursor: "pointer";
}

.ScholarshipManagment .custom-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.ScholarshipManagment .editButton {
  color: black;
  border-color: rgb(199, 195, 195);
}

.ScholarshipManagment .editButton:hover {
  color: white;
  border: none;
}

.ScholarshipManagment .Edit {
  width: 100%;
  height: 10%;
  font-size: 90%;
  background-color: transparent;
  border-style: ridge;
}
.ScholarshipManagment .Edit:hover {
  /* margin-top: 4%;
margin-bottom: 4%; */
  font-size: 105%;
  background-color: transparent;
  border-style: none;
}

.ScholarshipManagment .addSchlorshipButton {
  /* color: #01477A;
background-color: #dae8fa; */
  color: white;
  /* background-color: #dae8fa; */
  background-color: #64748b;
}

.ScholarshipManagment button.search-button {
  /* position: sticky; */
  position: relative;
  right: 0;
  bottom: 39%;
  left: 74%;
  /* padding: 10px; */
  height: 30%;
  border-top: 30px;
  border-color: black;
  /* background-color: white; */
  background-color: transparent;
  color: #0c0c0c;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.ScholarshipManagment .ForRowfont {
  font-size: 93%;
}
.ScholarshipManagment .ForRowfontOther {
  font-size: 93%;
}

.ScholarshipManagment .upcoming-Satus-color {
  color: #ffb155;
  background-color: #ffb155;
}

.ScholarshipManagment.loader-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScholarshipManagment .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.ScholarshipManagment .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* loader */
.ScholarshipManagment .loader-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScholarshipManagment .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.ScholarshipManagment .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 
 style={{
                              fontWeight: "300",
                              border: "revert-layer", // Remove default border on focus
                              outline: "none", // Remove default outline on focus
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                              // border: `10px solid ${setLiveFilter || '10px'}`,
                              // Add a custom box shadow on focus
                            }} */
