
@media (min-width: 2000px) and (max-width: 4000px) {
    .view-height{
        height: 60vh !important
    }
  }
  
  @media (min-width: 1400px) and (max-width: 2000px) {
    .view-height{
        height: 90vh !important
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .view-height{
        height: 90vh !important
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .view-height{
        height: 80vh !important
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .view-height{
        height: 50vh !important
    }
  }
  
  @media (max-width: 768px) {
    .view-height{
        /* height: 50vh !important */
    }
  }
  
  @media (min-width: 480px) and (max-width: 768px) {
    .view-height{
        /* height: 55vh !important */
    }
  }
  
  @media screen and (max-width: 480px) {
    .view-height{
        /* height: 60vh !important */
    }
  }
  