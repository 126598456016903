
  
  @media (min-width: 2000px) and (max-width: 4000px) {
  }
  
  @media (min-width: 1400px) and (max-width: 2000px) {
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
  }
  
  @media (min-width: 480px) and (max-width: 768px) {
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .card-img-overlay {
      /* height: fit-content !important; */
      /* font-size:small; */
    }
  }
  
  @media screen and (max-width: 320px) {
   .container .mobile-hide{
    display: none !important;
   }
   
  }
  