* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
}

.container-overlap {
  width: 90%;
  max-width: 1000px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.left {
  width: 60%;
  height: 580px;
  background: url(../assests/img/overlapupdated.png) no-repeat center / cover;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}

.right {
  width: 50%;
  min-height: 400px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 20px;
  color: black;
  margin-left: -150px;
  border: 0.5px solid #a5a5a6;
  position: relative;
  z-index: 2;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../assests/img/blueimg.png) no-repeat center / cover;
  opacity: 0.3; /* Adjust opacity as needed */
  z-index: 0;
  border-radius: 20px;
}
.background-overlay {
  position: absolute;
  top: 25px;
  left: 400px;
  right: 974px;
  bottom: 0;
  background: url(../assests/img/blueimg.png) no-repeat center / cover;
  opacity: 1;
  z-index: 0;
  border-radius: 20px;
  width: 50%;
  height: 535px;
}
.text-container {
  z-index: 3;
}

.text-box {
  display: flex;
  margin-bottom: 20px;
}

.text-box img {
  margin-right: 15px;
}

.text-box h2 {
  font-size: 20px;
  color: #3b76ef;
}

.text-box span {
  display: block;
  margin-top: 5px;
  color: #333;
}

@media (min-width: 480px) and (max-width: 768px) {
  .container-overlap {
    flex-direction: column;
  }

  .left {
    width: 100%;
    height: 400px;
  }

  .right {
    width: 90%;
    margin: 0;
    margin-top: -100px;
  }
  .background-overlay {
    display: none;
   
  }
}



/* @media (min-width: 480px) and (max-width: 768px) {
  .hide-card {
    display: none !important;
  }
} */

@media screen and (max-width: 480px) { 
/* @media (min-width: 480px) and (max-width: 768px) { */

  section {
    display: none;
  }
  .image-container {
    display: none;
  }
  .image2-container {
    display: none;
  }
  .cover-image-overlapping {
    display: none;
  }
  .countries-cover-section span {
    font-size: 1.9rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 0%;
    margin-top: 5%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 85%;
    margin-top: -9%;
  }
  .hidden-card-mobile {
    display: contents !important;
  }
  .home-banner-text-heading h1 {
    font-weight: 400;
    font-size: 2rem;
  }
  .home-banner-text-heading {
    margin-top: 10%;
  }
  .countries-cover-section p {
    font-size: 0.7rem;
  }

  .card-1-container {
    position: relative;
    height: 379px;
  }

  .ourstoryblueimg {
    height: 577px;
  }
  .inclusivity-card,
  .inclusivity-blue-img {
    width: 100%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    width: 69%;
    top: 77%;
    left: 31%;
    display: none;
  }

  .ourstory-container {
    position: relative;
    height: 351px;
    width: 100%;
    max-width: 380px;
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; 
  }
  .ourstory2 img {
    height: 606px;
    display: none !important;
  }

 
  .ourstory:nth-child(1) {
    top: -12px;
    left: 31px;
    width: 291px;
  }
  .ourstory:nth-child(2) {
    top: 0;     
    left: 0; 
  }


  .ourstory-container2 {
    position: relative;
    height: 600px;
    width: 100%;
    max-width: 393px;
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .ourstory2:nth-child(1) {
    top: -15px;
    left: 12px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }
}
