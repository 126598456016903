a.profile-link-button {
    color: black;
}
a.profile-link-button:hover {
    color: #2167b1;
}


.loader-container-schol {
  position: relative; /* Step 1: Set parent container to relative position */
  width: 100%; /* Optional: Adjust width as needed */
  height: 50vh; /* Optional: Adjust height as needed */
  display: flex; /* Optional: Use flexbox for centering */
  justify-content: center; /* Optional: Center horizontally */
  align-items: center; /* Optional: Center vertically */
}

.loader-schol {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-schol::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%; /* Step 2: Center horizontally */
  top: 50%; /* Step 3: Center vertically */
  transform: translate(-50%, -50%); /* Step 4: Translate back by half of the loader's size */
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

    


@media (min-width: 320px) and (max-width: 480px) {
    .complete-profile {
      margin-top: 5%;
      margin-bottom: 5%;
    }
    .loan-overflow {
      overflow: scroll;
      height: 100%;
      margin: 0px;
    }
    .hide-mobile {
      overflow: none;
      width: 100%;
      display: none !important;
  
    }
    .hide-br br{
      display: none !important;
  }
  }
  
  @media screen and (min-width: 320px)  {
  .hide-br br{
    display: none !important;
  }
  }