/* .active {
  background-color: azure;
} */

.nav-link[aria-current="page"] {
  text-decoration: underline wavy #f8c348 0 15rem;
  text-underline-offset: 0.5rem;
}
.AdminSidebar .forBackroundColor{
  background-color: rgb(223, 236, 236);
  color: #01477A;
}

.AdminSidebar.icon-color{

}

.forAlignment{
  align-items:justify;
}


.custom-nav-pills .nav-link {
  /* Customize the default text color for nav-pills */
  color: #070706;
}

/* .custom-nav-pills .nav-link.active, .custom-nav-pills .nav-link:hover {
  

  background-color: #32a852;

} */


/* styles.css */
.AdminSidebar .custom-nav-pills .nav-link {
  /* color: #392996; */
  /* background-color: #007bff; */
}

.AdminSidebar .custom-nav-pills .nav-link.active
/* .AdminSidebar .custom-nav-pills .nav-link:hover  */
{
  color: #01477A;
  ;
  
  background-color: #E5ECF1;
}

@media (min-width:1365px) and (max-width:1400px) {
   li{
    font-size: small;
  }
}
