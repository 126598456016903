.parent-container {
  position: relative;
  display: inline-block; /* Ensure the container only takes as much width as necessary */
}

.positioned-button {
  position: relative;
  bottom: 10px; /* Adjust this value based on your desired vertical position */
  right: 10px; /* Adjust this value based on your desired horizontal position */
}
/* Media query for screens 480px to 767px (Small devices like smartphones) */
@media (min-width: 480px) and (max-width: 767px) {
   /* Styles for devices with width between 480px and 767px */
   .heightAdjust {
     margin-top: 1rem !important;
   }
 }
 
 /* Media query for screens 768px to 991px (Medium devices like tablets) */
 @media (min-width: 768px) and (max-width: 991px) {
   /* Styles for devices with width between 768px and 991px */
   .heightAdjust {
     margin-top: 5.8rem !important;
   }
 }
 
 /* Media query for screens 992px to 1199px (Large devices like desktops) */
 @media (min-width: 992px) and (max-width: 1199px) {
   /* Styles for devices with width between 992px and 1199px */
   .heightAdjust {
     margin-top: 10.5rem !important;
   }
 }
 
 /* Media query for screens 1200px to 1399px (Extra large devices like large desktops) */
 @media (min-width: 1200px) and (max-width: 1399px) {
   /* Styles for devices with width between 1200px and 1399px */
   .heightAdjust {
     margin-top: 10.6rem !important;
   }
 }
 
 /* Media query for screens 1400px and above (Extra extra large devices) */
 @media (min-width: 1400px) {
   /* Styles for devices with width 1400px and above */
   .heightAdjust {
     margin-top: 10.6rem !important;
   }
 }
 