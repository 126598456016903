.text-primary {
    color: #2167b1 !important  ;
  }
  
  @media (min-width: 2000px) and (max-width: 4000px) {
    .custome-height-image{
        height: 450px;
    }
  }
  
  @media (min-width: 1400px) and (max-width: 2000px) {
    .custome-height-image{
        height: 400px;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .custome-height-image{
        height: 400px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .custome-height-image{
        height: 400px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .custome-height-image{
        height: 400px;
    }
  }
  
  @media (min-width: 480px) and (max-width: 768px) {
    .custome-height-image{
        height: 350px;
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .custome-height-image{
        height: 250px;
    }
  }
  
  @media (min-width: 280px)  and (max-width: 320px)  {
    .custome-height-image{
        height: 250px;
    }
  }