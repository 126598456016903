/* .home-countries-cover-section span {
    font-size: 5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-countries-cover-section {
    /* margin-top: 10%; 
  } */


  /* Default styles for all devices */
/* .home-countries-cover-section span {
  font-size: 5rem;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

/* Media query for small devices (phones) */
@media (min-width: 300px) and (max-width: 575px) {
  .home-countries-cover-section span {
    font-size: 2rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  
  .home-countries-cover-section span {
    font-size: 5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Media query for medium devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
  
  .home-countries-cover-section span {
    font-size: 5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Media query for larger phones and small tablets */
@media (min-width: 768px) and (max-width: 991px) {
  
  .home-countries-cover-section span {
    font-size: 5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Media query for larger devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  
  .home-countries-cover-section span {
    font-size: 5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Media query for extra-large devices (large desktops) */
@media (min-width: 1200px) {
 
  .home-countries-cover-section span {
    font-size: 5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
