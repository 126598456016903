.blogsManagement .forBackground{
background-color:  #EFF2F5;

}

.blogsManagement .forBackgroundTwo{
    background-color: #F8F9FB;

}

.blogsManagement .custom-table{
    border-radius: 2px; /* Adjust the radius as needed */
    overflow: hidden; 
  }

.blogsManagement .headingtext-color{
    color:#64748B;
  }

  .blogsManagement .addSchlorshipButton{
    /* color: #01477A;
    background-color: #dae8fa; */
    color: white;
    /* background-color: #dae8fa; */
    background-color: #64748B;
    }
    
    .blogsManagement .custom-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      outline: none;
    }
    