
.close-button {
  position: absolute;
  top: 10px; /* Adjust the top spacing as needed */
  right: 10px; /* Adjust the right spacing as needed */
}
/* 
@media (min-width: 320px) and (max-width: 480px) {
  .small-btn-mobile {
    
  }
}

@media screen and (max-width: 320px) {
 .small-btn-mobile{
  
 }
 
} */
@media (max-width: 320px) {
  .custom-smaller-button {
    font-size: 10px !important; /* Adjust font size */
    padding: 5px 10px !important; /* Adjust padding */
  }
}

/* Define custom styles for larger devices */
@media (min-width: 320px) {
  .custom-smaller-button {
    font-size: inherit !important; /* Revert to default font size */
    padding: inherit !important; /* Revert to default padding */
  }
}