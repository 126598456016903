.AddScholarship{
    background-color: #EFF2F5;
}
.AddScholarship .schlorshipBackground{
background-color: #F9FAFC;
}



.AddScholarship .forInputFiled{
    border: 'revert-layer';
    outline: "none"; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
  }

