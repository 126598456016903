@media (min-width: 320px) and (max-width: 480px) {
  .text-sm-start {
    text-align: left !important;
  }
}

@media (min-width: 280px) and (min-width: 320px) {
  .text-sm-start {
    text-align: left !important;
  }
}
