.scholarshipinformation-card {
  border: none;
}


.close-button-info {
  position: absolute;
  top: 10px; /* Adjust the top spacing as needed */
  right: 10px; /* Adjust the right spacing as needed */
}


.text-primary {
  color: #2167b1 !important  ;
}

@media (min-width: 2000px) and (max-width: 4000px) {
  .button-custom {
    width: 50% !important;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .button-custom {
    width: 50% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .button-custom {
    width: 50% !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .button-custom {
    width: 50% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .button-custom {
    width: 100% !important;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .button-custom {
    width: 100% !important;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .complete-profile {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .loan-overflow {
    overflow: hidden;
    height: 100%;
    margin: 0px;
  }
  .hide-mobile {
    overflow: none;
    width: 100%;
    display: none !important;
  }
  .hide-br br {
    display: none !important;
  }
  .button-custom {
    width: 100% !important;
  }
}


@media (min-width: 280px) and (max-width: 320px) {
  .hide-br br {
    display: none !important;
  }
  .button-custom {
    width: 100% !important;
  }
}