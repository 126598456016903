
/* .carousel-indicators .active {
    background-color:#2167b1
} */

.carousel-indicators.cbtn button {
    /* Your custom styles here */
    background-color:#2167b1
    /* Add more styles as needed */
  }


  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}


.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
  color: #2167b1 !important ;
  /* background-color: #87c1ff; */
  
} 



.text-primary {
  color: #2167b1 !important  ;
}
.skeleton-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
}

.skeleton-image {
  width: 100%;
  height: 650px;
  background-color: #e0e0e0;
  border-radius: 5px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}

@media (min-width: 2000px) and (max-width: 4000px) {
  .custome-height{

  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .custome-height{

  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .custome-height{
    
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .custome-height{

  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .custome-height{

  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .custome-height{

  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .carousel-height{

  }
}

@media screen and (min-width: 320px)  {
  .carousel-height{
  }
}