/* body {
  font-family: -apple-system, BlinkMacSystemFont, "Josefin Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,"Josefin Sans", "Courier New",
    monospace;
} */


body {
  font-family: 'Josefin Sans', sans-serif !important;
}


.student-side .text-primary{
  color: #2167b1 !important  ;
}

.btn-primary{
  background-color: #2167b1 !important  ;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #2167b1 !important;
}

img, svg {
  vertical-align: top !important;
}

.no-clear-button::-webkit-clear-button {
  display: none;
}

.no-clear-button::-ms-clear {
  display: none;
}

.no-clear-button::-moz-clear {
  display: none;
}

/* Hide the clear button (cross icon) in WebKit browsers (Chrome, Safari) */
input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Hide the clear button in Internet Explorer */
input[type="date"]::-ms-clear {
  display: none;
}
/* Hide the clear button in Internet Explorer */
input[type="date"]::-moz-clear {
  display: none;
}
