.loan-background-container {
    background-image: url('../assests/img/loansAccordian.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    transition: background 0.5s ease; 
  }
  
  .loan-background-container:hover {
    background-image: url('../assests/img/loansAccordian.png'); 
  }
  


  /* Define initial styles for the dropdown */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 12px;
    z-index: 1;
    opacity: 0; /* Set initial opacity to 0 */
    transition: opacity 0.5s ease; /* Apply smooth transition to opacity */
  }
  
  /* Show the dropdown when hovering over the parent element */
  .dropdown:hover .dropdown-content {
    display: block;
    opacity: 1; /* Change opacity to 1 on hover */
  }
  