 .schlorshipBackground{
    background-color: #EFF2F5;
    }
    
    

    /* loader */
.winnerPage .loader-container {
   position: relative;
   width: 100%;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .winnerPage .loader {
   width: 48px;
   height: 48px;
   border-radius: 50%;
   display: inline-block;
   border-top: 4px solid #fff;
   border-right: 4px solid transparent;
   box-sizing: border-box;
   animation: rotation 1s linear infinite;
 }
 
 .winnerPage .loader::after {
   content: "";
   box-sizing: border-box;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 48px;
   height: 48px;
   border-radius: 50%;
   border-bottom: 4px solid #2167b1;
   border-left: 4px solid transparent;
 }
 
 @keyframes rotation {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }