.Addblogs .forBackgroundColor{
    background-color:#F9FAFC;
    
}

.Addblogs .forPageBackground{
    background-color: #F8F9FB;

}

.Addblogs .file-upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ddd;
    padding: 20px;
    cursor: pointer;
  }

.Addblogs .file-input {
    display: none;
  }


 .Addblogs .forInputFiledAddblog{
    font-weight: 400;
    border: 'revert-layer';
    outline: "none";
   
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .Addblogs .pageBackroundforBothPage{
    background-color:  #FFFFFF;
    ;
  }
  /* background: #EFF2F5; */



.Addblogs .info-on-hover{
    position: relative;
    top: 0%;
    left:56%;
    background-color: forestgreen;
}
.Addblogs .info-on-hover svg {
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    border-radius: 50%;
}



.Addblogs .banner-image-One{
  position: relative;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}


.Addblogs .absolute-content-info{
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 0.2rem;
}



.Addblogs .absolute-content-infoTWo{
  position: absolute;
  left: 0;
  top: 0;
  
}
.Addblogs .box-style-info{
    border-radius: 4px;
    border: 1px solid #7D808E;
    background: #383C41;
    width: 240px;
    position: relative;
    z-index: 10;
}

.Addblogs .box-style-infoTwo{
  border-radius: 4px;
  border: 1px solid #7D808E;
  background: #383C41;
  width: 240px;
  position: relative;
  z-index: 10;
}
/* cropper easy */

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  /* -webkit-appearance: none;
  -moz-appearance: none; */
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* -moz-appearance: none; */
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.addcover-container{
  position: relative;
}
.addcover-click {
  position: absolute;
  top: 0%;
  left: 346px;
}
.addbanner-container{
  position: relative;
}

.addbanner-click {
  position: absolute;
  top: 0%;
  left: 346px;
}
