.border-container{
    border-style: dashed;
    border-color: rgb(206, 203, 203);
    position: relative;
}
.uploaded-image{
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
}
.custom-file-upload input[type='file']{
    display: none;
}
.country-container{
    position: relative;
    width: 100%;
    height: auto;
    max-height: 200px;
   
}
.country{
    position: absolute;
    background-color: #eaeaea;
    color: rgb(0, 0, 0);
    /* padding: 10px; */
    border-radius: 10px;
    width: 335px;
    cursor: pointer;
    border: 1px  solid #DDE2E4;
}

.country :nth-child(1){
    top: 0px;
    left: 0px;
}
.country-background:hover{
    background-color: rgb(255, 255, 255);
   
}

.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  .img-preview {
    overflow: hidden;
  }

  /*  */