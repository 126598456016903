.abouthomesection {
  background-image: url(../assests/img/Banner2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.countries-cover-section {
  /* background-image: url(../assests/img/Path2Study3.png); */
  background-image: url(../assests/img/about26.png);
  /* background-image: url(../assests/img/path2studyLogo3.png); */
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 12;  */
  /* background-color: rgb(250, 249, 249), 0.201; */
  /* opacity: 0.5; */
  height: auto;
  max-width: 100%;
  width: 100%;
}

.home-text-about {
  position: absolute;
  top: 16rem;
}

.about-yellow-img {
  z-index: 0;
  height: 65%;
  margin-top: 30.7%;
  margin-left: -0.1%;
}
.about-img-card-index-1 {
  z-index: 1;
  margin: 10%;
}
.about-card-1 {
  z-index: 0;
}

.countries-cover-section span {
  font-size: 5rem;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.our-story-container {
  z-index: 2;
  margin-top: 7%;
  margin-left: 48%;
}

.double-qoutes {
  position: absolute;
  z-index: 1;
  margin-left: 6.4%;
  margin-top: 2%;
}
.double-qoutes-sec {
  position: absolute;
  z-index: 1;
  margin-left: 89%;
  margin-top: -5%;
}
.yellow-img-about-down {
  z-index: 0;
  margin-top: 14%;
  margin-left: 56.5%;
  height: 75%;
}

.container-we-click-different {
  position: absolute;
  /* top: 330%; */
  left: 10%;
  z-index: 1;
  width: 45%;
  height: 50%;
}
.our-mission-heading-font {
  font-weight: 300;
}

.home-banner-text-heading {
  margin-top: 25%;
}

/* .home-banner-text-heading h1{
    font-weight: 400;
    font-size: 3rem
} */

/* ----------------------- card 1st hide------------------ */

.hidden-card-mobile {
  display: none;
}

/* ------------------Image-Over-Lapping-Down-Side-Style-------------------*/

.image-container {
  position: relative;
  width: 100%;
  height: 400px;
  max-width: 700px; /* Adjust as needed */
  margin: auto;
  margin-top: 9%;
}

.image {
  position: absolute;
  width: 100%;
  height: auto;
  border-radius: 20px; /* Optional: Add some styling */
}

/* Adjust the positioning of images as needed */
.image:nth-child(1) {
  top: 34px;
  left: -4px;
  width: 42%;
  border-radius: 0px;
  height: 87%;
}

.image:nth-child(2) {
  top: -32px;
  left: -40%;
  border-radius: 20px;
}

.image:nth-child(3) {
  top: 45px;
  left: -41%;
  width: 100%;
}

/* ------------- upper container------------ */

.image2-container {
  position: relative;
  width: 100%;
  max-width: 700px; /* Adjust as needed */
  margin: auto;
  margin-top: 10%;
}

.image2 {
  position: absolute;
  width: 100%;
  height: auto;
  border-radius: 20px; /* Optional: Add some styling */
}

/* Adjust the positioning of images as needed */

.image2:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0px;
}

.image2:nth-child(2) {
  top: 34px;
  left: 352px;
  width: 42%;
  border-radius: 0px;
}
.image2:nth-child(3) {
  top: -32px;
  left: -40%;
  border-radius: 20px;
}

.image2:nth-child(4) {
  top: 17px;
  left: 40%;
}

/* --------------------Cards----------------- */

.card-1-container {
  position: relative;
  height: 430px;
}

.inclusivity-card,
.inclusivity-blue-img {
  width: 90%;
  position: absolute;
}

.inclusivity-card {
  top: 0px;
  left: 0px;
}

.inclusivity-blue-img {
  width: 70%;
  top: 317px;
  left: 100px;
}

@media (min-width: 2000px) and (max-width: 4000px) {
  /* -------------image-contaner-upper---------------- */

  .image2-container {
    position: relative;
    width: 100%;
    max-width: 720px; /* Adjust as needed */
    margin: auto;
    margin-top: 15%;
  }

  .image2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2:nth-child(1) {
    top: -12px;
    left: 377px;
    width: 51%;
  }

  .image2:nth-child(2) {
    top: -32px;
    left: -40%;
  }

  .image2:nth-child(3) {
    top: 1px;
    left: 41%;
  }

  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 720px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 14%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -7px;
    left: -24px;
    width: 50%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 1px; /* Adjust the overlap */
    left: -41%; /* Adjust the overlap */
    height: 491px;
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 41%;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  /* -------------image-contaner-upper---------------- */

  .image2-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    margin-top: 10%;
  }

  .image2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 20px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2:nth-child(1) {
    top: 457px;
    left: 200px;
    width: 20%;
    height: 20%;
  }

  .image2:nth-child(2) {
    top: 1px;
    left: 336px;
    width: 44%;
  }
  .image2:nth-child(3) {
    top: -37px;
    left: -43%;
    border-radius: 20px;
  }

  .image2:nth-child(4) {
    top: 11px;
    left: 43%;
  }

  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 7%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: 75px;
    left: -4px;
    width: 43%;
    border-radius: 0px;
    height: 100%;
  }

  .image:nth-child(2) {
    top: 38px;
    left: 246px;
    width: 100%;
    border-radius: 0px;
  }

  .image:nth-child(3) {
    top: 85px;
    left: -47%;
  }

  image:nth-child(4) {
    top: 45px;
    left: -40%;
    /* height: 334px; */
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 35%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  /* -------------image-contaner-upper---------------- */

  .image2-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    margin-top: 10%;
  }

  .image2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 20px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */

  .image2:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0px;
  }

  .image2:nth-child(2) {
    top: 30px;
    left: 357px;
    width: 43%;
    border-radius: 0px;
  }
  .image2:nth-child(3) {
    top: -32px;
    left: -40%;
  }
  .image2:nth-child(4) {
    top: 360px;
    left: 132%;
    width: 14%;
    opacity: 0.2;
  }

  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    height: 400px;
    max-width: 700px; /* Adjust as needed */
    margin: auto;
    margin-top: 10%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 20px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: 34px;
    left: -4px;
    width: 42%;
    border-radius: 0px;
    height: 87%;
  }

  .image:nth-child(2) {
    top: -32px;
    left: -40%;
    border-radius: 20px;
  }

  .image:nth-child(3) {
    top: 45px;
    left: -41%;
    width: 100%;
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 41%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* -------------image-contaner-upper---------------- */

  .image2-container {
    position: relative;
    width: 100%;
    max-width: 500px; /* Adjust as needed */
    margin: auto;
    margin-top: 4%;
  }

  .image2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2:nth-child(1) {
    top: 347px;
    left: 635px;
    width: 22%;
  }
  .image2:nth-child(2) {
    top: 44px;
    left: 57%;
    width: 48%;
  }

  .image2:nth-child(3) {
    top: 12px;
    left: -43%;
    height: 419px;
  }
  .image2:nth-child(4) {
    top: 57px;
    left: 43%;
    height: 329px;
    overflow: auto;
  }
  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 500px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 14%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -11px;
    left: -40px;
    width: 51%;
    height: 89%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 1px; /* Adjust the overlap */
    left: -41%; /* Adjust the overlap */
    height: 335px;
    overflow: auto;
  }

  /*------------------cards-------------------- */

  .card-1-container {
    position: relative;
    height: 430px;
  }

  .inclusivity-card,
  .inclusivity-blue-img {
    width: 90%;
    position: absolute;
    /* border:13px;
    border-radius: 30px; */
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
    /* border-radius: 25px;
    border: 2px solid #73AD21; */
  }

  .inclusivity-blue-img {
    width: 70%;
    top: 353px;
    left: 75px;
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 45%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* --------------------upper-container-image----------------- */

  .image2 h3 {
    font-size: 1rem;
  }
  .image2-container {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin: auto;
    margin-top: 10%;
  }

  .image2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2:nth-child(1) {
    top: 235px;
    left: 484px;
    width: 20%;
  }
  .image2:nth-child(2) {
    top: -33px;
    left: 51%;
    width: 52%;
  }

  .image2:nth-child(3) {
    top: -54px;
    left: -40%;
    overflow: auto;
    height: 22rem;
  }
  .image2:nth-child(4) {
    top: -23px;
    left: 33%;
    overflow: auto;
    height: 18rem;
  }
  /* --------------------image-container-down------------------ */
  .image-container {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin-top: 16%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -7px;
    left: -29px;
    width: 48%;
    height: 71%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 2px; /* Adjust the overlap */
    left: -41%; /* Adjust the overlap */
    height: 263px;
    overflow: auto;
  }
  .countries-cover-section {
    margin-top: 40%;
  }
  .countries-cover-section span {
    font-size: 3.5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* -----------Cards------------- */

  .card-1-container {
    position: relative;
    height: 500px;
  }

  .inclusivity-card,
  .inclusivity-blue-img {
    width: 95%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    height: 40%;
    width: 68%;
    top: 320px;
    left: 212px;
  }
}

@media (max-width: 768px) {
  .image {
    width: 100%;
  }

  .image:nth-child(2),
  .image:nth-child(3) {
    top: 20%;
    left: 20%;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  /* --------------image-container-upper----------------- */

  .image2 h3 {
    font-size: 1rem;
  }
  .image2-container {
    position: relative;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
    margin-top: 4%;
  }

  .image2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2:nth-child(1) {
    top: 259px;
    left: 461px;
    width: 16%;
  }
  .image2:nth-child(2) {
    top: 9px;
    left: 61%;
    width: 50%;
  }

  .image2:nth-child(3) {
    top: -8px;
    left: -38%;
    height: 321px;
  }
  .image2:nth-child(4) {
    top: 15px;
    left: 30%;
    height: 270px;
    overflow: auto;
  }

  /* ------------------image-container-down----------------- */
  .image h3 {
    font-size: 1rem;
  }
  .image-container {
    position: relative;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
    margin-top: 20%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -9px;
    left: -29px;
    width: 49%;
    height: 73%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 0px;
    left: -40%;
    height: 270px;
    overflow: auto;
  }

  /* ---------------------------------------- */

  .countries-cover-section {
    margin-top: 39%;
  }
  .countries-cover-section span {
    font-size: 3rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 2.4%;
    margin-top: 2%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 84%;
    margin-top: -9%;
  }
}

@media screen and (max-width: 480px) {
  .image-container {
    display: none;
  }
  .image2-container {
    display: none;
  }
  .cover-image-overlapping {
    display: none;
  }
  .countries-cover-section span {
    font-size: 1.9rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 0%;
    margin-top: 5%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 85%;
    margin-top: -9%;
  }
  .hidden-card-mobile {
    display: block;
  }
  .home-banner-text-heading h1 {
    font-weight: 400;
    font-size: 2rem;
  }
  .home-banner-text-heading {
    margin-top: 10%;
  }
  .countries-cover-section p {
    font-size: 0.7rem;
  }
  /* -----------Cards------------ */

  .card-1-container {
    position: relative;
    height: 379px;
  }

  .ourstoryblueimg {
    height: 577px;
  }
  .inclusivity-card,
  .inclusivity-blue-img {
    width: 100%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    width: 69%;
    top: 77%;
    left: 31%;
    display: none;
  }

  .ourstory-container {
    position: relative;
    height: 351px;
    width: 100%;
    max-width: 380px;
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }
  .ourstory2 img {
    height: 606px;
    display: none !important;
    /* height: 768px; */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -12px;
    left: 31px;
    width: 291px;
  }
  .ourstory:nth-child(2) {
    top: 0; /* Adjust the overlap */
    left: 0; /* Adjust the overlap */
  }

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    height: 600px;
    width: 100%;
    max-width: 393px;
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -15px;
    left: 12px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }
}
@media (min-width: 381px) and (max-width: 450px) {
  .image-container {
    display: none;
  }
  .image2-container {
    display: none;
  }
  .cover-image-overlapping {
    display: none;
  }
  .countries-cover-section span {
    font-size: 1.9rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 0%;
    margin-top: 5%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 85%;
    margin-top: -9%;
  }
  .hidden-card-mobile {
    display: block;
  }
  .home-banner-text-heading h1 {
    font-weight: 400;
    font-size: 2rem;
  }
  .home-banner-text-heading {
    margin-top: 10%;
  }
  .countries-cover-section p {
    font-size: 0.7rem;
  }
  /* -----------Cards------------ */

  .card-1-container {
    position: relative;
    height: 379px;
  }

  .ourstoryblueimg {
    height: 577px;
  }
  .inclusivity-card,
  .inclusivity-blue-img {
    width: 100%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    width: 69%;
    top: 77%;
    left: 31%;
    display: none;
  }

  .ourstory-container {
    position: relative;
    height: 351px;
    width: 100%;
    max-width: 380px;
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }
  .ourstory2 img {
    height: 621px;
    /* height: 768px; */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -12px;
    left: 31px;
    width: 291px;
  }
  

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    height: 600px;
    width: 100%;
    max-width: 393px;
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -15px;
    left: 12px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }
}
@media (min-width: 361px) and (max-width: 380px) {
  .ourstoryblueimg {
    height: 659px;
    width: 250px;
  }
  .ourstory-container {
    position: relative;
    height: 400px;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -11px;
    left: -4px;
    width: 304px;
  }
  .ourstory:nth-child(2) {
    top: 0; /* Adjust the overlap */
    left: 0; /* Adjust the overlap */
  }

  .ourstory img {
    /* height: 429px; */
    height: 407px;
  }

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    width: 100%;
    /* height: 770px; */
    height: 660px;

    max-width: 380px; /* Adjust as needed */
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -14px;
    left: -1px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ourstory2 img {
    height: 660px;
    /* height: 768px; */
  }
}

@media (min-width: 329px) and (max-width: 360px) {
  .ourstoryblueimg {
    height: 659px;
    width: 250px;
  }
  .ourstory-container {
    position: relative;
    height: 400px;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -11px;
    left: -4px;
    width: 304px;
  }
  .ourstory:nth-child(2) {
    top: 0; /* Adjust the overlap */
    left: 0; /* Adjust the overlap */
  }

  .ourstory img {
    /* height: 429px; */
    height: 407px;
    
  }

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    width: 100%;
    height: 710px;
    /* height: 690px; */

    max-width: 380px; /* Adjust as needed */
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
    /* margin-bottom: 3rem; */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -14px;
    left: -1px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ourstory2 img {
    /* height: 700px; */
    /* height: 670px; */
    height: 45rem;
    display: none !important;
  }
}

@media (min-width: 320px) and (max-width: 330px) {
  .ourstoryblueimg {
    height: 659px;
    width: 250px;
  }
  .ourstory-container {
    position: relative;
    height: 400px;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -11px;
    left: -4px;
    width: 304px;
  }
  .ourstory:nth-child(2) {
    top: 0; /* Adjust the overlap */
    left: 0; /* Adjust the overlap */
  }

  .ourstory img {
    /* height: 429px; */
    height: 407px;
    
  }

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    width: 100%;
    height: 770px;
    /* height: 690px; */

    max-width: 380px; /* Adjust as needed */
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
    /* margin-bottom: 3rem; */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -14px;
    left: -1px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ourstory2 img {
    /* height: 700px; */
    height: 768px;
    display: none !important;
  }
}


@media (min-width: 300px) and (max-width: 319px) {
  .ourstoryblueimg {
    height: 659px;
    width: 235px;
  }
  .ourstory-container {
    position: relative;
    height: 470px;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -11px;
    left: -4px;
    width: 282px;
  }
  .ourstory:nth-child(2) {
    top: 0; /* Adjust the overlap */
    left: 0; /* Adjust the overlap */
  }

  .ourstory img {
    height: 480px;
  }

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    width: 100%;
    height: 870px;
    max-width: 380px; /* Adjust as needed */
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -14px;
    left: -1px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ourstory2 img {
    height: 839px;
    display: none !important;
  }
}

@media (min-width: 280px) and (max-width: 300px) {
  .ourstoryblueimg {
    height: 659px;
    width: 208px;
  }
  .ourstory-container {
    position: relative;
    height: 480px;
    width: 100%;
    max-width: 380px; /* Adjust as needed */
    margin: auto;
  }

  .ourstory {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory:nth-child(1) {
    top: -11px;
    left: -4px;
    width: 262px;
  }
  .ourstory:nth-child(2) {
    top: 0; /* Adjust the overlap */
    left: 0; /* Adjust the overlap */
  }

  .ourstory img {
    height: 502px;
  }

  /* ------------------------------------- */

  .ourstory-container2 {
    position: relative;
    width: 100%;
    height: 910px;
    max-width: 380px; /* Adjust as needed */
  }

  .ourstory2 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .ourstory2:nth-child(1) {
    top: -14px;
    left: -1px;
  }
  .ourstory2:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ourstory2 img {
    height: 887px;
    display: none !important;
  }
}

@media screen and (max-width: 280px) {
  .inclusivity-blue-img {
    width: 79%;
    top: 84%;
    left: 22%;
  }
  .card-1-container {
    height: 420px;
  }
}
