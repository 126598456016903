.LoanManagement .pageBackground{
background-color:#EFF2F5;
}

.LoanManagement .borderColor{
/* border-color: #ae5454; */
border-style: ridge;
/* border: 20px; */
}

.LoanManagement button.search-buttonLoan {
    position: absolute;
    right: 0;
    top:30.5%;
    right:16.5%;
   
    height: 30%;
    border-top:30px ;
    border-color: black;
 
    background-color: transparent;
    color: #0c0c0c; 
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }

  .LoanManagement .viewDetails{
    color: black; 
    border-color: rgb(199, 195, 195);
  }
  
  .LoanManagement .viewDetails:hover{
    color: white; 
    
  }
  

.LoanManagement  .rowHeading{
    color: #64748B;
      }
.LoanManagement .custom-button{
    background-color: transparent;
border: none;
}


.LoanManagement .addLoan{
    color: white;
/* background-color: #dae8fa; */
background-color: #64748B;
}

