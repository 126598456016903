.w_result{
    background-color: #FAFCFF;
}
.bg_appli{
    background-color: #E7EEFF;;
}
#hoverwin:hover {
    background-color: #3771C8;
    color: white;
}
.rowHeading{
    color: #64748B;
}

.winnerColor{
background-color: #FFDD55;
}