.Edit .forBackgroundColor {
  background-color: #f9fafc;
}

.Edit .forPageBackground {
  background-color: #eff2f5;
}

.Edit .file-upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ddd;
  padding: 20px;
  cursor: pointer;
}

.Edit .file-input {
  display: none;
}

.Edit .forInputFiledAddblog {
  font-weight: 400;
  border: "revert-layer";
  outline: "none";

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Edit .box-style-info {
  border-radius: 4px;
  border: 1px solid #7d808e;
  background: #383c41;
  width: 240px;
  position: relative;
  z-index: 10;
}

.Edit .info-on-hover svg {
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-radius: 50%;
}

.absolute-content-info {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 0.2rem;
}

.Edit .react-datepicker-wrapper {
  display: block;
}

.editclick-container{
  position: relative;
}
.editclick {
  position: absolute;
  top: 0%;
  left: 328px;
}

.editbanner-container{
  position: relative;
}
.editclick-banner{
  position: absolute;
  top: 0%;
  left: 328px;
}