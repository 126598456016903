.AdminLogin{
    background-image:url( '../assets/img/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}


.AdminLogin.forfontweigth{
font-weight: 10;
}

.AdminLogin .forInputFiled{
    font-weight: 400;
    border: 'revert-layer';
    outline: "none";   
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
  }