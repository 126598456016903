/* .loan-overflow {
  overflow: scroll;
  height: 100%;
  margin: 0px;
} */

/* Overflow Auto */
.loan-overflow-auto {
  overflow: auto;
}

/* Overflow Hidden */
.loan-overflow-hidden {
  overflow: hidden;
}

/* Overflow Visible */
.loan-overflow-visible {
  overflow: visible;
}

/* Overflow Scroll card in the*/
.loan-overflow-scroll {
  overflow: scroll;
  height: 8rem;
}
/* on hover for input tag */
.form-control {
  border: 1px solid black;
  box-shadow: none;
}
.form-control:hover {
  border-color: 0.5px solid rgb(3, 3, 45);
  box-shadow: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide-loan {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-loan img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide-loan swiper-slide-active {
  color: #2167b1 !important ;
}

.loan-overflow .forMargin {
  margin-top: 16rem;
}
/* 
.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
  color: #2167b1 !important ;
  background-color: #87c1ff;
} */

/*  Loader css */

.loader-container {
  position: relative; /* Step 1: Set parent container to relative position */
  width: 100%; /* Optional: Adjust width as needed */
  height: 100vh; /* Optional: Adjust height as needed */
  display: flex; /* Optional: Use flexbox for centering */
  justify-content: center; /* Optional: Center horizontally */
  align-items: center; /* Optional: Center vertically */
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%; /* Step 2: Center horizontally */
  top: 50%; /* Step 3: Center vertically */
  transform: translate(
    -50%,
    -50%
  ); /* Step 4: Translate back by half of the loader's size */
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 2000px) and (max-width: 4000px) {
  .image-center-loan-card {
    margin-top: 6rem !important;
  }
  .complete-profile {
    margin-top: 35%;
    margin-bottom: 35%;
    /* overflow: hidden; */
  }
  .loan-overflow {
    overflow: scroll;
    height: 885px;
    margin: 0px;
  }
  .custom-Add {
    width: 54rem;
    overflow-y: scroll;
  }
  .custom-Add-width {
    height: 55rem;
    overflow-y: scroll;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .image-center-loan-card {
    margin-top: 6rem !important;
  }
  .complete-profile {
    margin-top: 35%;
    margin-bottom: 35%;
    /* overflow: hidden; */
  }
  .loan-overflow {
    overflow: scroll;
    height: 886px;
    margin: 0px;
    width: fit-content;
  }
  .swiper-button-next {
    margin-top: 100px;
  }
  .swiper-button-prev {
    margin-top: 100px;
  }
  .custom-Add {
    width: 52rem;
    overflow-y: scroll;
  }
  .custom-Add-width {
    height: 55rem;
    overflow-y: scroll;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .image-center-loan-card {
    margin-top: 6rem !important;
  }
  .complete-profile {
    margin-top: 50%;
    margin-bottom: 35%;
  }
  .loan-overflow {
    overflow: scroll;
    height: 885px;
    margin: 0px;
    width: 100%;
  }
  .swiper-button-next {
    margin-top: 100px;
  }
  .swiper-button-prev {
    margin-top: 100px;
  }
  .custom-Add {
    width: 52rem;
    overflow-y: scroll;
  }
  .custom-Add-width {
    height: 55rem;
    overflow-y: scroll;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .image-center-loan-card {
    margin-top: 5rem !important;
  }
  .complete-profile {
    margin-top: 50%;
    margin-bottom: 35%;
  }
  .loan-overflow {
    overflow: scroll;
    height: 885px;
    margin: 0px;
  }
  .swiper-button-next {
    margin-top: 100px;
  }
  .swiper-button-prev {
    margin-top: 100px;
  }
  .custom-Add {
    width: 52rem;
    overflow-y: scroll;
  }
  .custom-Add-width {
    height: 55rem;
    overflow-y: scroll;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .image-center-loan-card {
    margin-top: 5rem !important;
  }
  .complete-profile {
    height: 100%;
    margin-top: 50%;
    margin-bottom: 35%;
  }
  .loan-overflow {
    overflow: scroll;
    height: 885px;
    margin: 0px;
  }
  .swiper-button-next {
    margin-top: 100px;
  }
  .swiper-button-prev {
    margin-top: 100px;
  }
  .custom-Add {
    width: 20rem;
    overflow-y: scroll;
  }
  .custom-Add-width {
    height: 55rem;
    overflow-y: scroll;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .image-center-loan-card {
    margin-top: 5rem !important;
  }
  .complete-profile {
    margin-top: 50%;
    margin-bottom: 5%;
  }
  .loan-overflow {
    overflow: scroll;
    height: 100%;
    margin: 0px;
  }
  .hide-mobile {
    overflow: none;
    width: 100%;
    display: none !important;
  }
  .swiper-button-next {
    margin-top: 100px;
  }
  .swiper-button-prev {
    margin-top: 100px;
  }
  .custom-Add {
    width: 20rem;
    overflow-y: scroll;
  }
  .custom-Add-width {
    height: 55rem;
    overflow-y: scroll;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .image-center-loan-card {
    margin-top: 0rem;
  }
  .hidemodal-header {
    display: none !important;
  }
  .complete-profile {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .loan-overflow {
    overflow: scroll;
    height: 100%;
    margin: 0px;
  }
  .hide-mobile {
    overflow: none;
    width: 100%;
    display: none !important;
  }
  .hide-br br {
    display: none !important;
  }
  .swiper-button-next {
    margin-top: 50px;
  }
  .swiper-button-prev {
    margin-top: 50px;
  }
  /* .custom-Add{
    width: 20rem;
    overflow-y: scroll;
  } */
}

@media (min-width: 280px) and (min-width: 320px) {
  .image-center-loan-card {
    margin-top: 0rem;
  }
  .hide-br br {
    display: none !important;
  }
  .hidemodal-header {
    display: none !important;
  }
  .swiper-button-next {
    margin-top: 50px;
  }
  .swiper-button-prev {
    margin-top: 50px;
  }
  /* .custom-Add{
    width: 20rem;
    overflow-y: scroll;
  } */
}

@media (min-width: 360px) and (max-width: 480px) {
  .loan-overflow .forMargin {
    margin-top: 1rem !important;
  }
  /* .custom-Add{
    width: 20rem;
    overflow-y: scroll;
  } */
}

/* @media screen and (max-width: 480px) {
  .complete-profile {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .loan-overflow {
    overflow: scroll;
    height: 100%;
    margin: 0px;
  }
  .hide-mobile {
    overflow: none;
    width: 100%;
    display: none !important;

  }
  .hide-br br{
    display: none !important;
}
} */
