
// Set the $primary variable
$primary: #2167b1; // Replace "yourNewColor" with the desired color code

// Import Bootstrap source stylesheet
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/maps"; // Add this line
@import "~bootstrap/scss/functions"; // Add this line
@import "~bootstrap/scss/variables"; // Add this line

// Merge with existing $theme-colors map
$theme-colors: map-merge(
  $theme-colors,
  (
    "primary": $primary,
    "text-primary": $primary
  )
);

// Set changes
@import "~bootstrap/scss/bootstrap";



