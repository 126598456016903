.abouthomesection {
  background-image: url(../assests/img/Banner2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.countries-cover-section {
  background-image: url(../assests/img/Path2Study3.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
}

@media (min-width: 2000px) and (max-width: 4000px) {
  .button-custom {
    width: 50% !important;
  }
  .blank {
    height: 575px;
    width: 100%;
    margin-bottom: 15rem;
    margin-top: 3rem;
  }
  /* -------------image-contaner-upper---------------- */

  .image2h-container-home {
    position: relative;
    width: 100%;
    max-width: 720px; /* Adjust as needed */
    margin: auto;
    margin-top: 5%;
  }

  .image2h {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2h:nth-child(1) {
    top: -12px;
    left: 377px;
    width: 51%;
  }

  .image2h:nth-child(2) {
    top: -32px;
    left: -40%;
  }

  .image2h:nth-child(3) {
    top: 1px;
    left: 41%;
    height: 491px;
  }

  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 720px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 14%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -7px;
    left: -24px;
    width: 50%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 1px; /* Adjust the overlap */
    left: -41%; /* Adjust the overlap */
    height: 491px;
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 41%;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .button-custom {
    width: 50% !important;
  }
  .button-custom{
    width: 50%;
  }
  .custome-height {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
  .custome-height-Bullet {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .blank {
    height: 40rem;
    width: 100%;
    margin-bottom: 10rem;
    margin-top: 3rem;
  }
  /* -------------image-contaner-upper---------------- */

  .image2h-container-home {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    margin-top: 2%;
  }

  .image2h {
    position: absolute;
    width: 100%;
    height: 450px;
    /* max-height:450px ; */
    border-radius: 20px; /* Optional: Add some styling */
  }

  .image3h {
    position: absolute;
    width: 100%;
    height: 410px;
    /* max-height:450px ; */
    border-radius: 20px; /* Optional: Add some styling */
    margin-top: 1rem;
  }

  /* Adjust the positioning of images as needed */
  .image2h-home:nth-child(1) {
    top: 38px;
    left: 358px;
    width: 41%;
  }
  .image2h:nth-child(2) {
    top: -9px;
    left: -40%;
    border-radius: 20px;
  }

  .image2h:nth-child(3) {
    top: 44px;
    left: 41%;
    height: 354px;
    /* overflow: auto; */
  }

  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 14%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: 37px;
    left: -4px;
    width: 42%;
    border-radius: 0px;
    height: 90%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  image:nth-child(3) {
    top: 45px;
    left: -40%;
    /* height: 334px; */
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 41%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .button-custom {
    width: 50% !important;
  }
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .blank {
    height: 515px;
    width: 100%;
    margin-bottom: 2rem;
  }
  /* -------------image-contaner-upper---------------- */

  .image2h-container-home {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    margin-top: 10%;
  }

  .image2h {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 20px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2h:nth-child(1) {
    top: 30px;
    left: 357px;
    width: 43%;
    border-radius: 0px;
  }
  .image2h:nth-child(2) {
    top: -32px;
    left: -40%;
  }

  .image2h:nth-child(3) {
    top: 43px;
    left: 44%;
    height: 334px;
  }
  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 10%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: 34px;
    left: 4px;
    width: 42%;
    border-radius: 0px;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 45px;
    left: -42%;
    /* height: 334px; */
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 41%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .button-custom {
    width: 50% !important;
  }
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .blank {
    height: 575px;
    width: 100%;
    margin-bottom: 9rem;
    margin-top: 3rem;
  }
  .scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  /* -------------image-contaner-upper---------------- */

  .image2h-container-home {
    position: relative;
    width: 100%;
    max-width: 500px; /* Adjust as needed */
    margin: auto;
    margin-top: 7%;
  }

  .image2h {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2h:nth-child(1) {
    top: -11px;
    left: 260px;
    width: 51%;
  }
  .image2h:nth-child(2) {
    top: -32px;
    left: -40%;
  }

  .image2h:nth-child(3) {
    top: 1px;
    left: 41%;
    overflow: auto;
    height: 335px;
  }

  /* ----------container-image-down-------------- */

  .image-container {
    position: relative;
    width: 100%;
    max-width: 500px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 14%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -7px;
    left: -24px;
    width: 50%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 1px; /* Adjust the overlap */
    left: -41%; /* Adjust the overlap */
    height: 335px;
  }

  /* ----------------------------------------- */

  .countries-cover-section {
    margin-top: 41%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .button-custom {
    width: 50% !important;
  }
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .blank {
    height: 575px;
    width: 100%;
  }
  .scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  /* --------------------upper-container-image----------------- */

  .image2h h3 {
    font-size: 1rem;
  }
  .image2h-container-home {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin: auto;
    margin-top: 15%;
  }

  .image2h {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2h:nth-child(1) {
    top: -15px;
    left: 235px;
    width: 48%;
  }
  .image2h:nth-child(2) {
    top: -32px;
    left: -40%;
  }

  .image2h:nth-child(3) {
    top: -5px;
    left: 41%;
    height: 177px;
    overflow: auto;
    height: 263px;
  }

  /* --------------------image-container-down------------------ */
  .image-container {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin-bottom: 14%;
    margin-top: 16%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -7px;
    left: -19px;
    width: 48%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 2px; /* Adjust the overlap */
    left: -41%; /* Adjust the overlap */
    height: 263px;
    overflow: auto;
  }
  .countries-cover-section {
    margin-top: 47%;
  }
  .countries-cover-section span {
    font-size: 3.5rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* -----------Cards------------- */

  .card-1-container {
    position: relative;
    height: 500px;
  }

  .inclusivity-card,
  .inclusivity-blue-img {
    width: 95%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    height: 40%;
    width: 68%;
    top: 320px;
    left: 212px;
  }
}

@media (max-width: 768px) {
  .blank {
    height: 28rem;
    width: 100%;
    /* margin-top: 3; */
  }
  .image {
    width: 100%;
  }

  .image:nth-child(2),
  .image:nth-child(3) {
    top: 20%;
    left: 20%;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 12px; /* Modify the font size as needed */
    margin: 3px;
    padding: 3px;
  }
  .smallbutton-font{
    font-size: x-small;
  }
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .blank {
    height: 28rem;
    width: 100%;
  }
  /* --------------image-container-upper----------------- */

  .image2h h3 {
    font-size: 1rem;
  }
  .image2h-container-home {
    position: relative;
    width: 100%;
    max-width: 265px; /* Adjust as needed */
    margin: auto;
    margin-top: 20%;
  }

  .image2h {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image2h:nth-child(1) {
    top: -15px;
    left: 159px;
    width: 49%;
  }
  .image2h:nth-child(2) {
    top: -32px;
    left: -40%;
  }

  .image2h:nth-child(3) {
    top: -8px;
    left: 41%;
    height: 177px;
    overflow: auto;
  }

  /* ------------------image-container-down----------------- */
  .image h3 {
    font-size: 1rem;
  }
  .image-container {
    position: relative;
    width: 100%;
    max-width: 265px; /* Adjust as needed */
    margin: auto;
    margin-top: 20%;
  }

  .image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image:nth-child(1) {
    top: -15px;
    left: -16px;
    width: 49%;
  }

  .image:nth-child(2) {
    top: -32px; /* Adjust the overlap */
    left: 41%; /* Adjust the overlap */
  }

  .image:nth-child(3) {
    top: 0px;
    left: -40%;
    height: 177px;
    overflow: auto;
  }

  /* ---------------------------------------- */

  .countries-cover-section {
    margin-top: 71%;
  }
  .countries-cover-section span {
    font-size: 3rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 2.4%;
    margin-top: 2%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 84%;
    margin-top: -9%;
  }
}

@media (min-width: 375px) and (max-width: 480px) {
  .button-custom{
    width: 100%;
  }
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 8px; /* Modify the font size as needed */
  }
  .smallbutton-font{
    font-size: small;
  }
  .hide-br br {
    display: none !important;
  }
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .blank {
    height: 36rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 6rem;
    /* padding-bottom: 3rem; */
  }
  .image-container {
    display: none;
  }
  .image2h-container-home {
    display: none;
  }
  .cover-image-overlapping {
    display: none;
  }
  .countries-cover-section span {
    font-size: 1.9rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 1.4%;
    margin-top: 2%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 82%;
    margin-top: -11%;
  }
  .hidden-card-mobile {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .home-banner-text-heading h1 {
    font-weight: 400;
    font-size: 2rem;
  }
  .home-banner-text-heading {
    margin-top: 10%;
  }
  .countries-cover-section p {
    font-size: 0.7rem;
  }
  /* -----------Cards------------ */

  .card-1-container {
    position: relative;
    height: 450px;
  }

  .inclusivity-card,
  .inclusivity-blue-img {
    width: 97%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    height: 40%;
    width: 68%;
    top: 280px;
    left: 127px;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  .button-custom{
    width: 100%;
  }
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 8px; /* Modify the font size as needed */
  }
  .smallbutton-font{
    font-size: small;
  }
  .hide-br br {
    display: none !important;
  }
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .blank {
    height: 38rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 8rem;
  }
  .image-container {
    display: none;
  }
  .image2h-container-home {
    display: none;
  }
  .cover-image-overlapping {
    display: none;
  }
  .countries-cover-section span {
    font-size: 1.9rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .double-qoutes {
    position: absolute;
    z-index: 1;
    margin-left: 1.4%;
    margin-top: 2%;
  }
  .double-qoutes-sec {
    position: absolute;
    z-index: 1;
    margin-left: 82%;
    margin-top: -11%;
  }
  .hidden-card-mobile {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .home-banner-text-heading h1 {
    font-weight: 400;
    font-size: 2rem;
  }
  .home-banner-text-heading {
    margin-top: 10%;
  }
  .countries-cover-section p {
    font-size: 0.7rem;
  }
  /* -----------Cards------------ */

  .card-1-container {
    position: relative;
    height: 450px;
  }

  .inclusivity-card,
  .inclusivity-blue-img {
    width: 97%;
    position: absolute;
  }

  .inclusivity-card {
    top: 0px;
    left: 0px;
  }

  .inclusivity-blue-img {
    height: 40%;
    width: 68%;
    top: 280px;
    left: 127px;
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .custome-height {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .blank {
    height: 46rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 8rem !important;
  }
  .smallbutton {
    padding: 5px 10px; /* Adjust the padding as per your requirement */
    font-size: 12px; /* Modify the font size as needed */
  }
  .smallbutton-font{
    font-size: x-small;
  }
}
/* @media screen and (min-width: 320px)  {
  .blank {
    height: 46rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 13rem;
  }
} */
/* 
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

/* // Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {
  .blank {
    height: 28rem;
    width: 100%;
  }
} */

/* // Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {
  .blank {
    height: 28rem;
    width: 100%;
  }
} */

/* // Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) {
  .blank {
    height: 575px;
    width: 100%;
  }
  .scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
} */
/* // X-Large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {
  .blank {
    height: 515px;
    width: 100%;
  }
} */
/* // XX-Large devices (larger desktops, 1400px and up) */
/* @media (min-width: 1400px) {
  .blank {
    height: 40rem;
    width: 100%;
  }
} */

/*

.home-text-about {
  position: absolute;
  top: 16rem;
}

.about-yellow-img {
  z-index: 0;
  height: 65%;
  margin-top: 30.7%;
  margin-left: -0.1%;
}
.about-img-card-index-1 {
  z-index: 1;
  margin: 10%;
}
.about-card-1 {
  z-index: 0;
}

.countries-cover-section span {
  font-size: 5rem;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.our-story-container {
  z-index: 2;
  margin-top: 7%;
  margin-left: 48%;
}

.double-qoutes {
  position: absolute;
  z-index: 1;
  margin-left: 6.4%;
  margin-top: 2%;
}
.double-qoutes-sec {
  position: absolute;
  z-index: 1;
  margin-left: 89%;
  margin-top: -5%;
}
.yellow-img-about-down {
  z-index: 0;
  margin-top: 14%;
  margin-left: 56.5%;
  height: 75%;
}

.container-we-click-different {
  position: absolute;
  top: 330%; // commented by original
  left: 10%;
  z-index: 1;
  width: 45%;
  height: 50%;
}
.our-mission-heading-font {
  font-weight: 300;
}

.home-banner-text-heading {
  margin-top: 25%;
}

/* .home-banner-text-heading h1{
      font-weight: 400;
      font-size: 3rem
  } */

/* ----------------------- card 1st hide------------------

.hidden-card-mobile {
  display: none;
}

/* ------------------Image-Over-Lapping-Down-Side-Style-------------------

.image-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust as needed 
  margin: auto;
  height: 400px;
}

.image {
  position: absolute;
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Add some styling 
}

/* Adjust the positioning of images as needed 
.image:nth-child(1) {
  top: 7px;
  left: -35px;
  width: 49%;
}

.image:nth-child(2) {
  top: -32px; /* Adjust the overlap 
  left: 41%; /* Adjust the overlap 
}

.image:nth-child(3) {
  top: 50px; /* Adjust the overlap 
  left: -41%; /* Adjust the overlap 
}

/* ------------- upper container------------ 

.image2h-container-home {
  position: relative;
  width: 100%;
  max-width: 700px; /* Adjust as needed 
  margin: auto;
  margin-top: 10%;
}

.image2h {
  position: absolute;
  width: 100%;
  height: auto;
  border-radius: 20px; /* Optional: Add some styling 
}

/* Adjust the positioning of images as needed 
.image2h:nth-child(1) {
  top: 34px;
  left: 352px;
  width: 42%;
  border-radius: 0px;
}
.image2h:nth-child(2) {
  top: -32px;
  left: -40%;
  border-radius: 20px;
}

.image2h:nth-child(3) {
  top: 45px;
  left: 41%;
  height: 334px;
}

/* --------------------Cards----------------- 

.card-1-container {
  position: relative;
  height: 430px;
}

.inclusivity-card,
.inclusivity-blue-img {
  width: 90%;
  position: absolute;
}

.inclusivity-card {
  top: 0px;
  left: 0px;
}

.inclusivity-blue-img {
  height: 40%;
  width: 70%;
  top: 317px;
  left: 100px;
}




*/
