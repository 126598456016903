input[type="date"]::-webkit-clear-button {
  display: none !important;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
}
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
}

input::-ms-clear {
  width: 0;
  height: 0;
}

.DashBoard .backggroundForDashboard {
  background-color: #eff2f5;
  /* background-color: blue; */
}

.DashBoard .backgroundForMainContainer {
  background-color: #f9fafc;
}

.DashBoard .file-upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ddd;
  padding: 20px;
  cursor: pointer;
}

.DashBoard .file-label {
  margin-bottom: 10px;
}

.DashBoard .file-input {
  display: none;
}

.DashBoard .headingtext-color {
  color: #64748b;
}

.DashBoard .custom-table {
  border-radius: 10px; /* Adjust the radius as needed */
  overflow: hidden;
}

.DashBoard .active-row {
  background-color: #f0f8ff; /* Change this to the desired background color */
}

/* Style for the icon when it's in an active row */
.DashBoard .icon.active {
  color: #008000; /* Change this to the desired color for the active state */
}

.dasboard-img-container1 {
  position: relative;
  height: 150px;
  width: 510px;
}
.dashboard-image1 {
  position: absolute;
}

.dasboard-img-container1 :nth-child(1) {
  top: -2px;
  left: 0;
}
.dasboard-img-container1 :nth-child(2) {
  top: 6px;
  left: 432px;
}
.dasboard-img-container1 :nth-child(3) {
  top: 6px;
  left: 469px;
}

.DashBoard .colorForBackground {
  background-color: #f9fafc;
}

.DashBoard .forBackgroundColorInput {
  background: #f8f8f8;
}

/* .background: #F8F8F8; */
.DashBoard .forInputFiled {
  font-weight: 400;
  border: "revert-layer";
  outline: "none";

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
