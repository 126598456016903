/* .custom-textfield label::before {
  content: "*";
  color: rgb(250, 249, 249); 
}
.custom-textfield label::after {
  content: "*";
  color: red; 
} */

.body {
  font-family: Josefin Sans;
}

.box-14 {
  width: 15.9375rem;
  height: 25.625rem;
  position: relative;
  top: 4rem;
  right: 5.81rem;
}

/*  */

.ourstory-container-contact {
  position: relative;
  height: 470px;
  width: 100%;
  max-width: 380px;
  margin: auto;
}

.ourstory-contact {
  position: absolute;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.ourstory-contact:nth-child(1) {
  top: -2%;
  left: 25px;
}
.ourstory-contact:nth-child(2) {
  top: 0;
  left: 0;
}

/*  */
label:required::after {
  content: none;
}

.hoverEffect {
  color: black;
}
.hoverEffect:hover {
  color: rgb(71, 126, 185);
}
.box-19 {
  width: 30.9375rem;
  height: 21.625rem;
  position: relative;
  top: 4rem;
  right: 5.81rem;
  background-color: white;
}

.contactInfo {
  color: #232323;

  text-align: justify;
  font-family: Josefin Sans;
  font-size: 2vh;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375vh; /* 122.222% */
  letter-spacing: 0.0675vh;
}

.contactBannerHead1 {
  font-family: Josefin Sans;
  color: #005128;
  /* font-size:4.5vh; */
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 4.05rem */
  text-transform: uppercase;
}

.contactBannerHead2 {
  color: #000;
  font-family: Josefin Sans;
  /* font-size: 3vh; */
  font-style: normal;
  font-weight: 300;
  line-height: 135%;
  text-transform: uppercase;
}

.fedbackTitle {
  color: #005128;

  font-family: Josefin Sans;
  font-size: 1.75vh;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 2.3625rem */
  text-transform: uppercase;
}
.contact-form {
  position: relative;
  top: 5.1vh;
  right: 16vw;
  z-index: 2;
  width: 38vw;
  height: 56vh;
  padding-bottom: 5%;
}

.formHeading {
  color: "#005128";
  font: "Josefin Sans";
  font-size: "2.8vh";
  font-weight: "600vh";
  /* lineHeight: '135%';
   extTransform: 'uppercase'; */
}

.sendButtonStyle {
  background-color: "#0C7449";
  width: "9vw";
  height: "5vh";
}

/* ----------------------------------- */

.image3-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: auto;
  margin-top: 10%;
  height: 600px;
}

.image3 {
  position: absolute;
  width: 100%;
  height: auto;
  border-radius: 20px; /* Optional: Add some styling */
}

/* Adjust the positioning of images as needed */
.image3:nth-child(1) {
  top: -38px;
  left: 366px;
  width: 58%;
  border-radius: 0px;
}

.image3:nth-child(2) {
  top: -74px;
  left: -44%;
  border-radius: 20px;
  height: 600px;
  width: 120%;
}

.image3:nth-child(3) {
  top: -30px;
  left: 41%;
  height: 500px;
}

/* ---------------------------------- */

@media screen and (max-width: 915px) {
  .laptopImg {
    margin-top: 3vh;
    position: relative;
    bottom: "3vh";
  }
}

@media screen and (max-width: 915px) {
  .laptopImg {
    margin-top: 3vh;
    position: relative;
    bottom: "3vh";
  }
}

@media screen and (max-width: 1024px) {
  .laptopImg {
    display: none;
  }
}
/* 
--------------------------------media query for overlaping divs in contact form----------------------- */
@media (max-width: 618px) {
  .uptoweb {
    display: none;
  }
}

/* ..................contact image for mobile---------------------- */

@media (min-width: 618px) {
  .contactImg {
    height: 7vh;
    width: 7vh;
    display: none;
  }

  .mobileForm {
    margin-top: 3vh;
    height: 7vh;
    width: 7vh;
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .image3-container {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin: auto;
    margin-top: 20%;
    height: 500px;
  }

  .image3 {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 20px; /* Optional: Add some styling */
  }

  /* Adjust the positioning of images as needed */
  .image3:nth-child(1) {
    top: -60px;
    left: 161px;
    width: 84%;
    border-radius: 0px;
  }

  .image3:nth-child(2) {
    top: -74px;
    left: -44%;
    border-radius: 20px;
    height: 500px;
    width: 120%;
  }

  .image3:nth-child(3) {
    top: -50px;
    left: 41%;
    height: 452px;
  }
}

/* for submit button */
@media (min-width: 488px) and (max-width: 963px) {
  .SubmitButttonResponsive {
    margin-top: 0;
    margin-bottom: 0;
    width: 2rem;
    height: 0.7rem;
  }

  .text-size-manage {
    font-size: 1.5rem;
  }
}

/* for submit button */
@media (min-width: 488px) and (max-width: 963px) {
  .SubmitButttonResponsive {
    margin-top: 0;
    margin-bottom: 0;
    width: 2rem;
    height: 0.7rem;
  }

  .text-size-manage {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex !important;
    align-items: center;
  }
  .PhoneInput--focus {
    border: transparent !important;
  }

  .PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1;
    /* The phone number input should shrink
       to make room for the extension input */
    min-width: 0;
    /* border: transparent !important; */
    border-width: 1px; /* Adjust the value to make the border thinner */
    border-top: #000;
    border-left: #000;
    border-right: #000;
    /* width: 22.7rem; */
    /* border-bottom-color: #232323; */
  }
  /* .ourstory-contact img {
    /* height: 479px;   
  } */
  .ourstory-container-contact {
    position: relative;
    height: 470px;
    width: 100%;
    max-width: 380px;
    margin: auto;
  }

  .ourstory-contact {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .ourstory-contact:nth-child(1) {
    top: -2%;
    left: 0%;
  }
  .ourstory-contact:nth-child(2) {
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 280px) {
  .ourstory-contact img {
    height: 479px;
  }
  .ourstory-container-contact {
    position: relative;
    height: 470px;
    width: 100%;
    max-width: 380px;
    margin: auto;
  }

  .ourstory-contact {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .ourstory-contact:nth-child(1) {
    top: -2%;
    left: 0%;
  }
  .ourstory-contact:nth-child(2) {
    top: 0;
    left: 0;
  }
}

/* for submit button */
@media (min-width: 360px) {
  .ourstory-contact {
    position: absolute;
    width: 90%;
    height: auto;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .ourstory-contact:nth-child(1) {
    top: -2%;
    left: 0%;
  }
  .ourstory-contact:nth-child(2) {
    top: 0;
    left: 0;
  }
}
/*  phone number css */

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #2167b1;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
  outline: #2167b1 !important;
}
.PhoneInput .PhoneInputInput:focus-visible {
  outline: none !important;
  /* outline-offset: 1px; */
  border: 1px solid #2167b1 !important;
  /* box-shadow: 0 0 5px transparent !important; 
  border-color: #2167b1 !important;  */
}
.PhoneInput--focus:focus {
  /* Add your custom focus styles here */
  border-color: #2167b1 !important; /* Change border color on focus */
  box-shadow: 0 0 5px #2167b1 !important; /* Add a box shadow on focus */
}
.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
  /* border: transparent !important; */
  border-width: 1px; /* Adjust the value to make the border thinner */
  border-top: #000;
  border-left: #000;
  border-right: #000;
  /* border-bottom: black; */
  /* width: 22.7rem; */
  border-bottom-color: gray;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4); /* Border color with 50% opacity */
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
