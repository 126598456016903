.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #2167b1;
}

.nav-link.deactive {
  color: rgb(67, 64, 64) !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .blank {
    height: 28rem;
    width: 100%;
  }
}

@media (max-width: 480px) and (min-width: 360px) {
  .Resources {
    margin-bottom: 15px !important;
  }

  .newButton {
    margin-bottom: 10px !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .navbar {
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

@media (min-width: 320px) and (max-width: 480px) {
  .offcanvas-backdrop.show {
    opacity: 0 !important;
  }
}

@media (min-width: 280px) and (min-width: 320px) {
  .offcanvas-backdrop.show {
    opacity: 0 !important;
  }
}

/* Custom CSS to decrease hamburger size */
/* .navbar-toggler-icon {
  font-size: 0.75rem; /* Adjust the font-size as needed 
  width: 1.5em; /* Adjust the width as needed 
  height: 1.5em; /* Adjust the height as needed 
} */

/* border while select icon  */
*,
*::before,
*::after {
  box-sizing: none !important;
  /* box-sizing: border-box; */
  border-radius: var(--bs-navbar-toggler-transition);
}
/* Optional: Adjust the padding around the icon */
.navbar-toggler {
  padding: 0.25rem 0.5rem; /* Adjust the padding as needed */
  border: none !important;
  /* border-radius: var(--bs-navbar-toggler-border-radius); */
  border-radius: var(--bs-navbar-toggler-transition);
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  /* box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); */
}

/* Customize hamburger icon */
.navbar-toggler-icon {
  background-image: url("../assests/img/menu.png") !important; /* Replace with path to your custom icon */
}

/* Rotate the hamburger icon to an "X" close icon when the menu is open */
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  transform: rotate(90deg); /* Adjust rotation as needed */
}

.navbar-toggler collapsed {
  border: transparent !important;
}

/* Select  */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  --bs-offcanvas-width: 25%;
}

:root {
  --offcanvas-width: 50%;
  --bs-offcanvas-width: 25%;
}
@media (min-width: 320px) and (max-width: 480px) {
  /* .custom-offcanvas{
     --offcanvas-width: 50%;
     --bs-offcanvas-width : 25%;
  } */
  /* .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  } */
  .offcanvas {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 65% !important;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 0.1rem !important;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5;
  }
}

@media (min-width: 280) and (max-width: 320) {
  /* .custom-offcanvas {
    --offcanvas-width: 50%;
    --bs-offcanvas-width: 25%;
  } */

  /* .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  } */
  .offcanvas {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 65% !important;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 0.1rem !important;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5;
  }
}
