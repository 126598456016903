


@media (min-width: 2000px) and (max-width: 4000px) {
    .hideinmobile{
        display: none;
    }
  }
  
  @media (min-width: 1400px) and (max-width: 2000px) {
    .hideinmobile{
        display: none;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .hideinmobile{
        display: none;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .hideinmobile{
        display: none;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .hideinmobile{
        display: none;
    }
  }
  
  @media (min-width: 480px) and (max-width: 768px) {
    .hideinmobile{
        display: none;
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
   
  }
  
  @media screen and (min-width: 320px)  {
   
  }