/* #card {
  position: relative;
  height: 285px;
  width: 381px;
  margin-bottom: 50px;
} */
.shadow-div {
  width: fit-content;
  height: 80px; /* Adjust height as needed */
  background: rgba(
    255,
    255,
    255,
    0.2
  ); /* Glass-like background color with opacity */
  backdrop-filter: blur(10px); /* Blur effect for the background */
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

.swiper-slide-winner {
  font-size: 18px;
  /* background: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.images {
  position: absolute;
  background: rgba(
    44,
    39,
    39,
    0.108
  ); /* Glass-like background color with opacity */
  backdrop-filter: blur(7px); /* Blur effect for the background */
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3); /* Shadow effect from bottom to mid */
  padding: 20px; /* Adjust padding as needed */
  background-color: #0000006f;
  /* width: fit-content; */
}
#card .images:nth-child(1) {
  top: 1px;
}
#card .images:nth-child(2) {
  top: 173px;
  left: 0px;
  width: -webkit-fill-available;
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .images {
    position: absolute;
    background: rgba(
      44,
      39,
      39,
      0.108
    ); /* Glass-like background color with opacity */
    backdrop-filter: blur(7px); /* Blur effect for the background */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 33px;
    border-bottom-right-radius: 33px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3); /* Shadow effect from bottom to mid */
    padding: 20px; /* Adjust padding as needed */
    background-color: #0000006f;
    /* width: fit-content; */
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}
@media (min-width: 451px) and (max-width: 480px) {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 390px !important;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 441px) and (max-width: 450px) {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 390px !important;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 401px) and (max-width: 440px) {
  #card {
    position: relative;
    height: 285px !important;
    width: 388px !important;
    margin-bottom: 50px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 173px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 401px) and (max-width: 412px) {
  #card {
    position: relative;
    height: 285px !important;
    width: 386px !important;
    margin-bottom: 50px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 173px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}

@media (min-width: 370px) and (max-width: 400px) {
  #card {
    position: relative;
    height: 285px !important;
    width: 347px !important;
    margin-bottom: 50px;
  }
}

@media (min-width: 361px) and (max-width: 370px) {
  #card {
    position: relative;
    height: 285px !important;
    width: 337px !important;
    margin-bottom: 50px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  #card {
    position: relative;
    height: 285px !important;
    width: 330px !important;
    margin-bottom: 50px;
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  
  #card {
    position: relative;
    height: 285px !important;
    width: 296px !important;
    margin-bottom: 50px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
  }
  #card-mobile {
    position: relative;
    height: 278px;
    width: 351px;
    margin-bottom: 35px;
  }

  .images-mobile {
    position: absolute;
  }

  #card-mobile .images-mobile:nth-child(1) {
    top: 1px;
  }
  #card-mobile .images-mobile:nth-child(2) {
    top: 163px;
    left: 0px;
  }

  #card-mobile .images-mobile:nth-child(3) {
    top: 186px;
    left: 0px;
  }
}
