
.navbar-nav .nav-tabs .nav-link{
    color: #2167b1 ;
    text-decoration: none;
  }

 a{
  text-decoration: none;
 }
 /* img, svg {
  vertical-align: text-top;
} */

img, svg {
  vertical-align: baseline;
}


@media (min-width: 320px) and (max-width: 480px) {
  
  .custom-span{
    font-size: small !important;
  }
}

@media (min-width: 280px) and (min-width: 320px)  {
  
  .custom-span{
    font-size: small !important;
  }
}
