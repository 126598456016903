.footer-brand {
  margin-right: 20%;
}

#faqs {
  scroll-behavior: smooth;
}

.f-shadow {
  /* box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.09); */

  box-shadow: 0 -1.2px 8px rgba(0, 0, 0, 0.1);
}

/* Basic CSS for hover effect */
.color-class {
  color: black; /* Set default color to black */
}

/* Change color to blue on hover */
.color-class:hover {
  color: var(--primary); /* Change color to blue on hover */
}
