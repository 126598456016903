@media (min-width: 2000px) and (max-width: 4000px) {
  .button-custom{
    width: 50% !important;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .button-custom{
    width: 50% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .button-custom{
    width: 50% !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
 
}

@media (min-width: 768px) and (max-width: 992px) {
    
}

@media (min-width: 480px) and (max-width: 768px) {
   
}

@media (min-width: 320px) and (max-width: 480px) {
    .hide-br br{
        display: none !important;
    }
}

@media screen and (min-width: 320px)  {
  .hide-br br{
    display: none !important;
  }
}