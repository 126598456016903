.AdminProfile .page-background{
 background-color: #EFF2F5;
}

.AdminProfile .profile-container-background{
 background-color:#F9FAFC;


}

.heading-color{
    color: #ACB2BA;
}


.Number-OF-ScholorShip{
  color:#B4BAC3;
}

/* Target the appearance of the dropdown */
input[type="month"]::-webkit-calendar-picker-indicator {
  /* Add your custom styles for the dropdown indicator here */
  /* color: #333;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px; */
  /* Add any other custom styles you wish */
}

/* Target the appearance of the dropdown panel itself */
input[type="month"]::-webkit-datetime-edit-fields-wrapper {
  /* Add your custom styles for the dropdown panel here */
  /* background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px; */
  /* Add any other custom styles you wish */
}

input[type="month"]::-webkit-datetime-edit-text {
  /* Add your custom styles here */
}
input[type="month"]::-webkit-datetime-edit-fields-wrapper {
  width: 500px; /* Replace 200px with your desired width */
}
@media (min-width:1600px) and (max-width:1700px) {
  .toSetName{
   font-size: large;
 }
}
