.winner_background {
  background-image: url("../assets/img/emoji.png");
  background-repeat: repeat;
  background: cover;
  background-size: 105% 105%;
}
.bg_resu {
  background-color: #eff2f5;
}
.bg_innerresult {
  background-color: #fafcff;
}
.bg_reinn {
  background-color: #eaf0f8;
}
#hover-button:hover {
  background-color: #3771c8;
  color: white;
}
.dates {
  color: #939eaf;
}
.applicants {
  color: #6d7580;
}

.custom-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
}

/* loader */
.result .loader-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.result .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #2167b1;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
